import { SplitText } from "@cyriacbr/react-split-text";
import * as React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import eventBus from "../../components/utils/eventBus";
import gsap from "gsap";

import TempPicture from "../../images/testing-image.jpg";
import coverCollectivite from "../../images/src/pages/realisations/desktop/bureaux_1_desktop.jpg";
import coverCloisons from "../../images/src/pages/realisations/desktop/separation_1_desktop.jpg";
import coverBoutique from "../../images/src/pages/realisations/desktop/commerces_1_desktop.jpg";
import coverPorte from "../../images/src/pages/realisations/desktop/portes_1_desktop.jpg";
import coverAmenagement from "../../images/src/pages/realisations/desktop/amenagement_1_desktop.jpg";
import "../../styles/realisations/index.scss";

class Realisation extends React.Component {
  componentDidMount() {
    gsap.set(".rea_header", { opacity: 0 });
    eventBus.on("Finalised", () => {
      gsap.set(".rea_header", { opacity: 1 });
      let tl = gsap.timeline();
      tl.fromTo(
        ".rea_header .line",
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, stagger: 0.2, duration: 0.6 }
      );
    });
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Nos réalisations | Menuiserie Perret</title>
          <meta
            name="description"
            type="description"
            content="Pensées pour les usagers et leur bien-être, nos menuiseries sont réalisées dans nos ateliers de la région lyonnaise et reflètent le travail de toute une équipe. "
          />
        </Helmet>
        <section className="rea_header">
          <h1 className="secondary-title">
            <SplitText
              LineWrapper={({ lineIndex, children }) => (
                <span className={"line wrapper" + " line" + lineIndex}>
                  {children}
                </span>
              )}
            >
              De la conception à la pose, nos menuiseries sont le fruit du
              travail de toute une équipe et sont entièrement réalisées dans nos
              ateliers.
            </SplitText>
          </h1>
        </section>
        <section className="content">
          <div>
            <picture>
              <img src={coverCollectivite} alt="Image temporaire"></img>
            </picture>
            <Link to="/realisations/collectivités" className="infos">
              <h2 className="quartrary">Collectivités</h2>
            </Link>
            <Link to="" className="button">
              <svg
                width="71"
                height="71"
                viewBox="0 0 71 71"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect opacity="0.5" width="71" height="71" fill="#23201D" />
                <path
                  d="M17.7926 19.4027L18.0764 25.2533L41.2517 24.1498L17.2276 50.6236L21.5783 54.5717L45.6024 28.0979L46.7058 51.2731L52.5979 50.9873L50.9876 17.7923L17.7926 19.4027Z"
                  fill="white"
                />
              </svg>
            </Link>
          </div>
          <div>
            <picture>
              <img src={coverCloisons} alt="Image temporaire"></img>
            </picture>
            <Link to="/realisations/cloisons-claustras" className="infos">
              <h2 className="quartrary">Cloisons, séparations et claustras</h2>
            </Link>
            <Link to="" className="button">
              <svg
                width="71"
                height="71"
                viewBox="0 0 71 71"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect opacity="0.5" width="71" height="71" fill="#23201D" />
                <path
                  d="M17.7926 19.4027L18.0764 25.2533L41.2517 24.1498L17.2276 50.6236L21.5783 54.5717L45.6024 28.0979L46.7058 51.2731L52.5979 50.9873L50.9876 17.7923L17.7926 19.4027Z"
                  fill="white"
                />
              </svg>
            </Link>
          </div>
          <div>
            <picture>
              <img src={coverBoutique} alt="Image temporaire"></img>
            </picture>
            <Link to="/realisations/boutiques-commerces" className="infos">
              <h2 className="quartrary">Bureaux, boutiques et commerces</h2>
            </Link>
            <Link to="" className="button">
              <svg
                width="71"
                height="71"
                viewBox="0 0 71 71"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect opacity="0.5" width="71" height="71" fill="#23201D" />
                <path
                  d="M17.7926 19.4027L18.0764 25.2533L41.2517 24.1498L17.2276 50.6236L21.5783 54.5717L45.6024 28.0979L46.7058 51.2731L52.5979 50.9873L50.9876 17.7923L17.7926 19.4027Z"
                  fill="white"
                />
              </svg>
            </Link>
          </div>
          <div>
            <picture>
              <img src={coverPorte} alt="Image temporaire"></img>
            </picture>
            <Link to="/realisations/portes-fenetres" className="infos">
              <h2 className="quartrary">Portes et fenêtres</h2>
            </Link>
            <Link to="" className="button">
              <svg
                width="71"
                height="71"
                viewBox="0 0 71 71"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect opacity="0.5" width="71" height="71" fill="#23201D" />
                <path
                  d="M17.7926 19.4027L18.0764 25.2533L41.2517 24.1498L17.2276 50.6236L21.5783 54.5717L45.6024 28.0979L46.7058 51.2731L52.5979 50.9873L50.9876 17.7923L17.7926 19.4027Z"
                  fill="white"
                />
              </svg>
            </Link>
          </div>
          <div>
            <picture>
              <img src={coverAmenagement} alt="Image temporaire"></img>
            </picture>
            <Link to="/realisations/amenagement" className="infos">
              <h2 className="quartrary">Aménagements intérieurs</h2>
            </Link>
            <Link to="" className="button">
              <svg
                width="71"
                height="71"
                viewBox="0 0 71 71"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect opacity="0.5" width="71" height="71" fill="#23201D" />
                <path
                  d="M17.7926 19.4027L18.0764 25.2533L41.2517 24.1498L17.2276 50.6236L21.5783 54.5717L45.6024 28.0979L46.7058 51.2731L52.5979 50.9873L50.9876 17.7923L17.7926 19.4027Z"
                  fill="white"
                />
              </svg>
            </Link>
          </div>
          <div className="panel"></div>
          <div className="panel"></div>
        </section>
      </>
    );
  }
}
export default Realisation;
